import { JsonProperty, Serializable } from "typescript-json-serializer"

function isFile(type: string): boolean {
  return type === "FILE"
}

function isFilter(filter: number): boolean {
  return filter === 1
}

@Serializable()
export default class DownloadHeader {
  id = 0

  @JsonProperty("download_header_id") downloadHeaderId!: number

  @JsonProperty() title!: string

  @JsonProperty({ name: "type", beforeDeserialize: isFile }) isFile!: boolean

  @JsonProperty({ beforeDeserialize: isFilter }) filter!: boolean

  hover = false

  static = false

  // eslint-disable-next-line
  component?: any;
}
