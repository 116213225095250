import { JsonProperty, Serializable } from "typescript-json-serializer"
import { dateToLocalString } from "@/types/News/helper"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class Blog {
  @JsonProperty("blog_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty("blog") content!: string

  @JsonProperty({ name: "created_date", beforeDeserialize: dateToLocalString }) date!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
