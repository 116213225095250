import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import DownloadHeader from "@/types/SupportAndDownload/Download/DownloadHeader"
import Download from "@/types/SupportAndDownload/Download/Download"

export default class DownloadApi extends DiamondBaseAPI {
  static listHeaders(): Promise<ResponseBase<DownloadHeader>> {
    return this.api.get("download-header").then(({ data }) => data)
  }

  static listDownloads(downloadId: number, productGroupId?: number): Promise<ResponseBase<Download>> {
    const params = {}
    if (productGroupId !== null) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.product_group_id = productGroupId
    }
    return this.api.get(`download/${downloadId}`, { params }).then(({ data }) => data)
  }
}
