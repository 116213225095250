import DiamondFile from "@/types/DiamondFile"
import { JsonProperty, Serializable } from "typescript-json-serializer"
import { dateToLocalString } from "@/types/News/helper"

@Serializable()
export default class Support {
  @JsonProperty("support_id") id!: number

  @JsonProperty("support_header_id") headerId!: number

  @JsonProperty("product_group_id") productGroupId!: number

  @JsonProperty() title!: string

  @JsonProperty() sort!: number

  @JsonProperty() link!: string

  @JsonProperty({
    name: "created_date",
    beforeDeserialize: dateToLocalString
  })
  createdDate!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  @JsonProperty({ type: DiamondFile }) cover!: DiamondFile
}
