





























import { Component, Prop } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import BackBar from "@/components/BackBar.vue"
import SupportApi from "@/api/SupportAndDownload/SupportApi"
import Blog from "@/types/SupportAndDownload/Support/Blog"
import { deserialize } from "typescript-json-serializer"
import BreakpointMixin from "../../../types/BreakpointMixin"

@Component({
  name: "BlogDetail",
  components: { BackBar }
})
export default class BlogDetail extends mixins(BreakpointMixin) {
  @Prop() blogId!: number

  private loading = false

  private blog: Blog = new Blog()

  private back() {
    this.$emit("back", 0)
  }

  private fetchData() {
    this.loading = true
    SupportApi.getBlog(this.blogId)
      .then(({ data }) => {
        this.blog = deserialize<Blog>(data, Blog)
      })
      .finally(() => {
        this.loading = false
      })
  }

  mounted() {
    this.fetchData()
  }
}
