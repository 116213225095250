
















import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import SiteRefList from "@/components/SupportAndDownload/Support/SiteRefList.vue"
import SiteRefCategory from "@/components/SupportAndDownload/Support/SiteRefCategory.vue"
import SiteRefProductGroupCategory from "@/types/SupportAndDownload/Support/SiteRefProductGroupCategory"
import SiteRefDetailComponent from "@/components/SupportAndDownload/Support/SiteRefDetailComponent.vue"
import InputMixin from "../../../types/InputMixin"
import BreakpointMixin from "../../../types/BreakpointMixin"

@Component({
  name: "DiamondSiteRefPage",
  components: { SiteRefList }
})
export default class DiamondSiteRefPage extends mixins(BreakpointMixin, InputMixin) {
  private appState = 0

  private productGroupId = -1

  private categoryId = -1

  private categoryName = ""

  private state = {
    0: SiteRefList,
    1: SiteRefCategory,
    2: SiteRefDetailComponent
  }

  mounted() {
    this.refreshState()
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    this.refreshState()
  }

  refreshState() {
    const { state } = this.$route.query
    const queryGroup = this.$route.query.group
    if (queryGroup) this.productGroupId = parseInt(queryGroup as string, 10)
    const queryCategory = this.$route.query.category
    if (queryCategory) this.categoryId = parseInt(queryCategory as string, 10)
    const queryName = this.$route.query.name
    if (queryName) this.categoryName = queryName as string
    if (`${state}` !== "undefined") {
      this.appState = parseInt(`${state}`, 10)
    }
  }

  private back(target: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        state: `${target}`
      }
    })
  }

  private viewDetail(category: SiteRefProductGroupCategory) {
    this.$router.push({
      query: {
        ...this.$route.query,
        group: `${this.productGroupId}`,
        category: `${category.productCategoryId}`,
        name: category.title,
        state: "2",
        shade: undefined
      }
    })
    // this.categoryId = category.productCategoryId;
    // this.categoryName = category.title;
    // this.appState = 2;
  }

  private viewCategory(groupId: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        group: `${groupId}`,
        state: "1"
      }
    })
    // this.productGroupId = groupId;
    // this.appState = 1;
  }
}
