






































































import { Component } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import SupportPage from "@/views/SupportAndDownload/SupportPage.vue"
import DownloadPage from "@/views/SupportAndDownload/DownloadPage.vue"
import { mixins } from "vue-class-component"
import TabMixin from "@/types/TabMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import InputMixin from "@/types/InputMixin"

@Component({
  name: "SupportAndDownloadPage",
  components: { ViewWrapper }
})
export default class SupportAndDownloadPage extends mixins(TabMixin, BreakpointMixin, InputMixin) {
  private tab = 0

  private menus = {
    0: {
      id: 0,
      label: "support.title",
      view: "support",
      hover: false,
      component: SupportPage
    },
    1: {
      id: 1,
      label: "download.title",
      view: "download",
      hover: false,
      component: DownloadPage
    }
  }

  private viewQueryMapper = {
    support: 0,
    download: 1
  }

  private setTab(view: string) {
    // @ts-ignore
    if (this.viewQueryMapper[view] === this.tab) return
    const id = view === "download" ? (this.$i18n.locale === "en" ? 18 : 3) : -2
    const lang = this.$i18n.locale
    const query = { view, lang }
    if (id) {
      // eslint-disable-next-line
      // @ts-ignore
      query.id = `${id}`
    }
    this.$router.push({ name: "SupportAndDownload", query })
  }

  private generateRoute(view: string) {
    const id = view === "download" ? (this.$i18n.locale === "en" ? 18 : 3) : -2
    const lang = this.$i18n.locale
    const query = { view, lang }
    if (id) {
      // eslint-disable-next-line
      // @ts-ignore
      query.id = `${id}`
    }
    return { name: "SupportAndDownload", query }
  }

  private changeMenu(menuId: number) {
    const view = menuId === 0 ? "support" : "download"
    const lang = this.$i18n.locale
    const query = { view, lang }
    const id = view === "download" ? (this.$i18n.locale === "en" ? 18 : 3) : -2

    if (id) {
      // eslint-disable-next-line
      // @ts-ignore
      query.id = `${id}`
    }
    this.$router.push({ name: "SupportAndDownload", query })
  }

  mounted() {
    const { view } = this.$route.query
    if (view) {
      // @ts-ignore
      this.tab = this.viewQueryMapper[view]
    }
  }

  get tabHeight(): string {
    const bp = this.$vuetify.breakpoint
    let height = 0
    if (bp.lgAndDown) {
      height = 54
    } else {
      height = 80
    }
    return `${height}px`
  }

  getTabClass(idx: number) {
    let cls = ""
    if (idx === 0) {
      cls = cls.concat("pl-6 ml-16 ml-xl-14 ")
    }
    if (this.tab === idx) {
      cls = cls.concat("custom-active-tab font-weight-semi-bold")
    } else {
      cls = cls.concat("font-weight-light")
    }
    return cls
  }
}
