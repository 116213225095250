import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import FAQ from "@/types/SupportAndDownload/Support/FAQ"
import SupportHeader from "@/types/SupportAndDownload/Support/SupportHeader"
import Support from "@/types/SupportAndDownload/Support/Support"
import SiteRefProductGroup from "@/types/SupportAndDownload/Support/SiteRefProductGroup"
import SiteRefProductGroupCategory from "@/types/SupportAndDownload/Support/SiteRefProductGroupCategory"
import ShadeColor from "@/types/SupportAndDownload/Support/ShadeColor"
import SiteRefDetail from "@/types/SupportAndDownload/Support/SiteRefDetail"
import Year from "@/types/News/Year"
import Blog from "@/types/SupportAndDownload/Support/Blog"

export default class SupportApi extends DiamondBaseAPI {
  static listBlogYears(): Promise<ResponseBase<Year[]>> {
    return this.api.get("blog-year").then(({ data }) => data)
  }

  static listBlogs(year?: number): Promise<ResponseBase<Blog[]>> {
    return this.api.get("blog", { params: { year } }).then(({ data }) => data)
  }

  static getBlog(blogId: number): Promise<ResponseBase<Blog>> {
    return this.api.get(`blog/${blogId}`).then(({ data }) => data)
  }

  static listHeaders(): Promise<ResponseBase<SupportHeader>> {
    return this.api.get("support-header").then(({ data }) => data)
  }

  static listFAQs(productGroupId: number): Promise<ResponseBase<FAQ>> {
    return this.api
      .get("/faq", {
        params: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          product_group_id: productGroupId
        }
      })
      .then(({ data }) => data)
  }

  static listSiteRefProductGroups(): Promise<ResponseBase<SiteRefProductGroup>> {
    return this.api.get("site-ref/product-group").then(({ data }) => data)
  }

  static listSiteRefProductGroupsFilter(): Promise<ResponseBase<SiteRefProductGroup>> {
    return this.api.get("product-group-for-filter").then(({ data }) => data)
  }

  static listSiteRefProductCategory(id: number): Promise<ResponseBase<SiteRefProductGroupCategory>> {
    return this.api
      .get("site-ref/product-category", {
        params: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          product_group_id: id
        }
      })
      .then(({ data }) => data)
  }

  static listSupports(supportId: number, productGroupId?: number): Promise<ResponseBase<Support>> {
    const params = {}
    if (productGroupId !== null) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.product_group_id = productGroupId
    }
    return this.api.get(`support/${supportId}`, { params }).then(({ data }) => data)
  }

  static listProductShadeColors(groupId: number, categoryId: number): Promise<ResponseBase<ShadeColor>> {
    return this.api
      .get("site-ref/shade-color", {
        params: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          product_group_id: `${groupId}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          product_category_id: `${categoryId}`
        }
      })
      .then(({ data }) => data)
  }

  static getSiteRefDetail(
    groupId: number,
    categoryId: number,
    shadeColorId?: number
  ): Promise<ResponseBase<SiteRefDetail>> {
    let params =
      shadeColorId !== undefined && shadeColorId > -1
        ? {
            // eslint-disable-next-line @typescript-eslint/camelcase
            product_group_id: `${groupId}`,
            // eslint-disable-next-line @typescript-eslint/camelcase
            product_category_id: `${categoryId}`,
            // eslint-disable-next-line @typescript-eslint/camelcase
            shade_color_id: `${shadeColorId}`
          }
        : {
            // eslint-disable-next-line @typescript-eslint/camelcase
            product_group_id: `${groupId}`,
            // eslint-disable-next-line @typescript-eslint/camelcase
            product_category_id: `${categoryId}`
          }
    return this.api
      .get("site-ref", {
        params: params
      })
      .then(({ data }) => data)
  }
}
