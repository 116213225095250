


































































































import { Component, Prop, Watch } from "vue-property-decorator"
// import ProductGroup from "@/types/Product/ProductGroup"
// import ProductApi from "@/api/Product/ProductApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import { mixins } from "vue-class-component"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import DownloadApi from "@/api/SupportAndDownload/DownloadApi"
import Download from "@/types/SupportAndDownload/Download/Download"
import SiteRefProductGroup from "@/types/SupportAndDownload/Support/SiteRefProductGroup"
import SupportApi from "@/api/SupportAndDownload/SupportApi"

@Component({
  name: "ImageTemplatePage"
})
export default class ImageTemplatePage extends mixins(InputMixin, BreakpointMixin) {
  @Prop()
  hasFilter!: boolean

  @Prop()
  downloadHeaderId!: number

  updating = false

  // @Prop({ type: Array as PropType<ProductGroup[]> })
  productGroups: SiteRefProductGroup[] = []

  data: Download[] = []

  productGroup = 1

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.mdAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }

  get iconFontSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndDown) {
      return "40px"
    }
    return "55px"
  }

  get thumbnailHeight() {
    const bp = this.$vuetify.breakpoint

    if (bp.lgAndDown) {
      return "165px"
    }
    return "247px"
  }

  get thumbnailWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.mdAndDown) {
      return "328px"
    }
    return "495px"
  }

  mounted() {
    if (this.hasFilter) {
      SupportApi.listSiteRefProductGroupsFilter()
        .then(({ data }) => {
          this.productGroups = _.map(data, (d) => deserialize<SiteRefProductGroup>(d, SiteRefProductGroup))
          this.productGroups = this.productGroups.filter(
            (productGroup: SiteRefProductGroup) =>
              productGroup.title !== "CALCULATION" &&
              productGroup.title !== "คำนวณวัสดุ" &&
              productGroup.productGroupId !== 23 &&
              productGroup.productGroupId !== 24
          )
          this.productGroup = this.productGroups[0].productGroupId
        })
        .then(this.fetchData)
    } else {
      this.fetchData()
    }
  }

  @Watch("productGroup")
  onProductGroupChange() {
    this.fetchData()
  }

  private fetchData() {
    this.updating = true
    const productGroup: number | undefined = this.hasFilter ? this.productGroup : undefined
    // eslint-disable-next-line no-undef
    DownloadApi.listDownloads(this.downloadHeaderId, productGroup)
      .then(({ data }) => {
        this.data = _.map(data, (d) => deserialize<Download>(d, Download))
      })
      .finally(() => {
        this.updating = false
      })
  }

  // eslint-disable-next-line class-methods-use-this
  private downloadFile(item: Download) {
    window.open(item.file.fileUrl, "_blank")
  }

  /** Group Data */
  private get groupData() {
    const LIMIT_DESTINATION = 3
    return this.data
      .map((item, index) => {
        if (index % LIMIT_DESTINATION === 0) {
          return this.data.slice(index, index + LIMIT_DESTINATION)
        }
      })
      .filter((item) => {
        return item
      })
  }
}
