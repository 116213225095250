













































































import { Component, Watch } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import SupportApi from "@/api/SupportAndDownload/SupportApi"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import FAQ from "@/types/SupportAndDownload/Support/FAQ"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"
import InputMixin from "@/types/InputMixin"
import SiteRefProductGroup from "@/types/SupportAndDownload/Support/SiteRefProductGroup"

@Component({
  name: "FAQPage",
  components: { ViewWrapper }
})
export default class FAQPage extends mixins(BreakpointMixin, InputMixin) {
  private productGroups: SiteRefProductGroup[] = []

  private faqs: FAQ[] = []

  private group = this.$i18n.locale === "en" ? 11 : -1

  private questionSet = -1

  private questionSubSet = -1

  private grouppedFaqs = {}

  get responsiveHeight(): string {
    const bp = this.$vuetify.breakpoint
    let height = 0
    if (bp.mdAndDown) {
      height = 60
    } else {
      height = 80
    }
    return `${height}px`
  }

  private getBorder(idx: number) {
    const type = idx === this.questionSet ? "solid" : "dashed"
    return { borderBottom: `1px ${type} black` }
  }

  private get currentGroupTitle() {
    return _.find(this.productGroups, ["productGroupId", this.group])?.title || ""
  }

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.mdAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    return SupportApi.listSiteRefProductGroupsFilter()
      .then(({ data }) => {
        this.productGroups = _.map(data, (g) => deserialize<SiteRefProductGroup>(g, SiteRefProductGroup))
        this.productGroups = this.productGroups.filter(
          (productGroup: SiteRefProductGroup) =>
            productGroup.title !== "CALCULATION" &&
            productGroup.title !== "คำนวณวัสดุ" &&
            productGroup.productGroupId !== 23 &&
            productGroup.productGroupId !== 24
        )
      })
      .then(() => {
        this.group = _.first(this.productGroups)?.productGroupId || this.group
        return SupportApi.listFAQs(this.group)
      })
      .then(({ data }) => {
        this.faqs = _.sortBy(
          _.map(data, (f) => deserialize<FAQ>(f, FAQ)),
          "sort"
        )
        this.grouppedFaqs = _.groupBy(this.faqs, "productCategory")
      })
  }

  @Watch("group")
  onGroupChange() {
    SupportApi.listFAQs(this.group).then(({ data }) => {
      this.faqs = _.sortBy(
        _.map(data, (f) => deserialize<FAQ>(f, FAQ)),
        "sort"
      )
      this.grouppedFaqs = _.groupBy(this.faqs, "productCategory")
    })
  }

  mounted() {
    this.fetchData()
  }
}
