






































































// import { Component, Prop, PropSync, Watch } from "vue-property-decorator"
import { Component, Prop, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import BackBar from "@/components/BackBar.vue"
import ShadeColor from "@/types/SupportAndDownload/Support/ShadeColor"
import SupportApi from "@/api/SupportAndDownload/SupportApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import SiteRefDetail from "@/types/SupportAndDownload/Support/SiteRefDetail"
import ImageDialog from "@/components/Global/ImageDialog.vue"
import BreakpointMixin from "../../../types/BreakpointMixin"
import InputMixin from "../../../types/InputMixin"

@Component({
  name: "SiteRefDetailComponent",
  components: { ImageDialog, BackBar }
})
export default class SiteRefDetailComponent extends mixins(BreakpointMixin, InputMixin) {
  // @PropSync('productGroupId')
  syncedProductGroupId!: number

  // @PropSync('productCategoryId')
  syncedProductCategoryId!: number

  @Prop() productCategoryName!: string

  private shadeColors: ShadeColor[] = []

  private details: SiteRefDetail[] = []

  private colorId = 1

  private image = false

  private imageUrl = ""

  private loading = false

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    this.fetchData()
  }

  private fetchData() {
    const queryCate = this.$route.query.category
    const queryGroup = this.$route.query.group
    if (queryCate) {
      this.syncedProductCategoryId = parseInt(queryCate as string, 10)
    }
    if (queryGroup) {
      this.syncedProductGroupId = parseInt(queryGroup as string, 10)
    }

    this.fetchColors().then(this.fetchDetail)
  }

  private mounted() {
    this.shadeColors = []
    this.fetchData()
  }

  private viewImage(imageUrl: string) {
    this.imageUrl = imageUrl
    this.image = true
  }

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.mdAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }

  get textAll() {
    return this.$t("global.all") as string
  }

  private onColorIdChange(colorId: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        shade: `${colorId}`
      }
    })
  }

  private fetchDetail() {
    this.loading = true
    const queryColor = this.$route.query.shade
    if (queryColor) {
      this.colorId = parseInt(queryColor as string, 10)
    }
    SupportApi.getSiteRefDetail(this.syncedProductGroupId, this.syncedProductCategoryId, this.colorId)
      .then(({ data }) => {
        this.details = _.map(data, (d) => deserialize<SiteRefDetail>(d, SiteRefDetail))
      })
      .finally(() => {
        this.loading = false
      })
  }

  private fetchColors() {
    return SupportApi.listProductShadeColors(this.syncedProductGroupId, this.syncedProductCategoryId).then(
      ({ data: colors }) => {
        this.shadeColors = _.map(colors, (c) => deserialize<ShadeColor>(c, ShadeColor))
        this.shadeColors.unshift({ id: -1, title: this.textAll })

        const queryColor = this.$route.query.shade
        if (queryColor) {
          this.colorId = parseInt(queryColor as string, 10)
        } else {
          this.colorId = _.first(this.shadeColors)?.id || 1
        }
      }
    )
  }
}
