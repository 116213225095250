import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class SiteRefDetail {
  @JsonProperty("site_ref_id") id!: number

  @JsonProperty("product_category") productCategory!: string

  @JsonProperty("product_group") productGroup!: string

  @JsonProperty() color!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile[] = []
}
